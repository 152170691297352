$publicPath:'';

 @import "~assets/css/global-variable.scss";

* {
  box-sizing: border-box;
}
/deep/.el-progress{
  text-align: left!important;
  display: flex;
  align-items: center;
}
/deep/.el-progress__text {
  color: $text-color-primary;
  flex: 0 0 55px;
}
/deep/.el-progress-bar__inner {
  background: linear-gradient(270deg, #13cdcd 0%, #006dff 100%);
}
// /deep/.el-progress-bar{
//   width: 180px;
// }
.page {
  width: 1440px;
  position: relative;
  background-color: transparent;
  min-height: calc(100% - 64px);
  padding: 32px;
  padding-bottom: 100px;
  margin: 0 auto;
  padding-top: 0;
  .page-tit {
    text-align: center;
    padding: 28px 0;
    margin: -32px -32px 32px;
    font-size: 20px;
    background: transparent;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
    color: $text-color-primary;
    &[data-dark="3"] {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .currentYear {
    font-size: 14px;
    color: #fff;
    margin-bottom: 24px;
  }
  .select-box {
    /deep/ .select-content {
      display: flex;
      height: 500px;
      .el-tabs {
        height: 100%;
        flex-shrink: 0;
        .el-tabs__content {
          display: none;
        }
        .el-badge__content {
          height: 14px;
          line-height: 14px;
        }
        .el-tabs__nav-scroll {
          padding-top: 20px;
        }
        .el-tabs__active-bar {
          width: 4px;
          border-radius: 2px 2px 2px 2px;
        }
        .el-tabs__item {
          font-size: 16px;
          transition: all 0.2s;
          padding-right: 30px;
          &.is-active {
            /* font-size: 20px; */
            transform: scale(1.2);
          }
        }
      }
      .select-emission {
        flex: 1;
        max-height: 100%;
        height: max-content;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        .myCard {
          margin-left: 24px;
          margin-bottom: 24px;
          position: relative;
          width: 217px;
          height: 85px;
          background: transparent;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid $panel-border-primary;
          cursor: pointer;
          transition: transform 0.3s;
          padding: 0 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: $fc-theme;
          }
          &.isAct,
          &:hover {
            border: 1px solid $fc-theme;
            background: $panel-bg-primary;
          }
          .icon {
            width: 48px;
            height: 48px;
            background-color: red;
            margin-right: 21px;
            background: url("./../../assets/img/lite/emission-icon.png")
              no-repeat center/95% 100%;
            flex-shrink: 0;
          }
          .name {
            flex: 1;
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: $text-color-primary;
            user-select: none;
          }
          .select {
            width: 22px;
            height: 22px;
            border: 1px solid #d9d9d9;
            border-radius: 50%;
          }
          &.isAct {
            .select {
              background: url("./../../assets/img/lite/active-icon.png")
                no-repeat center/cover !important;
            }
          }
        }
      }
    }
  }
  /deep/ .selectTime {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner,
    .el-form-item__content {
      width: 118px;
    }
    .el-form-item + .el-form-item {
      margin-left: 32px;
    }
  }
}
.report-form {
  .tooltip {
    margin-top: 8px;
    font-size: 14px;
    font-family: PingFangSC-Medium-, PingFangSC-Medium;
    color: #13cccdff;
    line-height: 22px;
  }
  .content {
    display: flex;
    .left {
      position: relative;
      padding: 33px;
      margin-right: 22px;
      width: 848px;
      height: 600px;
      background: transparent;
      border-radius: 4px 4px 4px 4px;
      .total-box {
        position: absolute;
        bottom: 20px;
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: $text-color-primary;
        & > div {
          margin-top: 5px;
          &:not(:last-child) {
            margin-right: 33px;
          }
          // span {
          //   font-weight: 600;
          //   margin: 0 5px;
          //   color: $fc-theme;
          // }
        }
      }
    }
    .right {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      color: #fff;
      justify-content: space-between;
      & > div {
        height: 50%;
        background-color: transparent;
      }
      .barChart {
        margin-bottom: 20px;
      }
      /*
      .pieChart {
        height: 330px;
      } */
    }
  }
}

/deep/.register-ruleForm {
  width: 1000px;
  margin: 0 auto;
  .myRow {
    padding-left: 57px;
  }
}
/deep/.survey-box {
  .el-row {
    margin-bottom: 24px;
    .el-col {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
  .survey-tit {
    margin-top: 38px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    color: $text-color-white;
    &::before {
      content: "*";
      display: inline-block;
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .el-input {
    width: 186px;
    .el-input__inner {
      font-size: 14px;
      height: auto;
      border: none;
      border-bottom: 1px solid #dcdcdc;
    }
  }
}
.myTitle {
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: bold;
  color: $text-color-white;
  margin-bottom: 24px;
  &::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 8px;
    width: 4px;
    height: 22px;
    background: $fc-theme;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
  }
}
.footer-btn-update{
    width: 136px;
    height: 32px;
    background: #12cace;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    float: left;
}
.footer-btn-box {
  position: absolute;
  left: 50%;
  bottom: 30px;
  display: flex;
  transform: translateX(-50%);
  &.third{
    bottom: 100px;
  }
  .reset-btn {
    width: 100px !important;
  }
    &-item1 {
    width: 88px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    opacity: 0.65;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #ffffff;
  }
  &-item2 {
    width: 88px;
    height: 32px;
    margin-left: 24px;
    border-radius: 3px 3px 3px 3px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    background: #006dffff;
  }
  .el-button {
    width: 128px;
  }
}
canvas {
  background-color: #fff;
}
